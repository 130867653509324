$(document).ready(function () {

    InitSwiper();
    InitVideoMask()
    
})

function InitSwiper() {
    let swiper;
    let internalConfigs;

    try {
        internalConfigs = $('.pd-swiper').first().data('configs');
    } catch (err) {
        console.log(err)
    }

    let swiperConfigs = {
        autoHeight: true,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        on: {
            init: function () {},
            slideChange: function (_swiper_) {
                console.log('slideChange', _swiper_)
                const swipes = $('.pd-swiper .swiper-wrapper .swiper-slide');
                const el_active = $(swipes[_swiper_.activeIndex]);
                const type_image = el_active.find('.swipe-image').length > 0;
                if (type_image) {
                    if (internalConfigs.attiva)
                        _swiper_.autoplay.start();
                    $('.swipe-video .video_mask_overlay').removeClass('hidden');
                }
            }
        }
    };

    if (internalConfigs.attiva && !isNaN(parseInt(internalConfigs.tempo))) {
        swiperConfigs['autoplay'] = {
            delay: parseInt(internalConfigs.tempo),
            disableOnInteraction: false
        }
    }

    swiper = new Swiper(".pd-swiper", swiperConfigs);

    $('.pd-swiper').on('stopAutoPlay', function() {
      swiper.autoplay.stop();
    })
    $('.pd-swiper').on('startAutoPlay', function() {
      swiper.autoplay.start();
    })
}

function InitVideoMask() {
    const video_mask_container = $('.swipe-video .video_mask_overlay');
    video_mask_container.on('click', function() {
        $(this).addClass('hidden');
    })
}